import "theme.light.less"
import "theme.dark.less"
import "./index.scss"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "universal-reducer"
import { Router } from "react-router-dom"
import store, { initStore } from "core/store"
import history from "core/history"
import { Spin } from "antd"
import { initAuth } from "core/user"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { initFirebase } from "core/firebase"


const render = (component: any) => {
  ReactDOM.render(component, document.getElementById("root"))
}

// TODO: stripe backend was removed (check git commits)
const STRIPE_API_KEY = "pk_test_51JbkqTKUYttSH4tzYjacboNODngULPYxHqEm02VEZ5xyqTbnIpxdLJjyqgtbMyAV56YU03zSNyAe0CxE3seIMdua00TjZmFKDi" // eslint-disable-line
const stripePromise = loadStripe(STRIPE_API_KEY)

Promise.resolve()
  .then(() => render(<Spin className="centered" />))
  .then(() => initStore())
  .then(() => initFirebase())
  .then(() => initAuth())
  .then(() => import("App"))
  .then(({ default: App }) => render(
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <Router history={history}>
          <App />
        </Router>
      </Elements>
    </Provider>
  ))

