import { notification } from "antd"

export const setAlertError = (description: any, message: any = "Error") => {
  notification.error({ message, description })
}

export const setAlertSuccess = (description: any, message: any = "Success") => {
  notification.success({ message, description })
}

export const setAlertWarning = (description: any, message: any = "Warning") => {
  notification.warning({ message, description })
}