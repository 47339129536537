import _ from "lodash"
import { actions } from "core/store"


const emptyObject = {}

export const selectLoader = (path:string):boolean => actions.get(`loaders.${path}`, 0) > 0
export const setLoader = (path:string):any => actions.update(`loaders.${path}`, (loader = 0) => loader + 1)
export const clearLoader = (path:string):any => actions.update(`loaders.${path}`, (loader = 0) => loader - 1)

export const selectAllLoaders = (): boolean => {
  const loaders = actions.get("loaders", emptyObject)
  return checkLoading(loaders)
}

const checkLoading = (loaders:any): boolean => Object.values(loaders).some((value) => {
  if (_.isPlainObject(value)) return checkLoading(value)
  if (_.isNumber(value)) return value > 0
  return false
})

