import { reducer, createActions, createStore } from "universal-reducer";
import _ from "lodash";


const EMPTY_OBJECT = {};

const STATE_KEY = "taskviews-state";
const PERSISTENT_PATHS = [
  "accountId"
];

const store = createStore(
  reducer,
  EMPTY_OBJECT,
  // @ts-ignore
  global.__REDUX_DEVTOOLS_EXTENSION__?.()
);


export const actions = createActions(store)

export const initStore = async () => {
  try {
    const persistentState = localStorage.getItem(STATE_KEY)
    const state = JSON.parse(persistentState) || EMPTY_OBJECT
    actions.set(state)
    store.subscribe(saveState)
  } catch (error) {
    console.error(error)
  }
}

const saveState = _.debounce(async () => {
  try {
    const state = store.getState()
    const persistentState = _.pick(state, PERSISTENT_PATHS)
    localStorage.setItem(STATE_KEY, JSON.stringify(persistentState))
  } catch (error) {
    console.error(error)
  }
}, 250, { leading: false, trailing: true })


export default store;

