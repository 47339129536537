import Axios from "axios"
import { getToken, selectAccount } from "core/user"

export const NO_AUTH_HEADER = "no-auth"

const axios = Axios.create()

axios.interceptors.request.use(
  async (config) => {
    const { headers } = config
    const { authorization } = headers
    if (authorization === NO_AUTH_HEADER) {
      delete headers.authorization
    } else if (!authorization) {
      const token = await getToken()
      headers.authorization = `Bearer ${token}`
    }

    const { id: accountId } = selectAccount()
    headers["x-accountid"] = accountId
    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    error.message = error?.response?.data?.message || error?.message
    return Promise.reject(error)
  }
)

export default axios