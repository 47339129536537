import firebase from "core/firebase"
import { setAlertError } from "core/alerts"
import { clearLoader, setLoader } from "core/loaders"
import { actions } from "core/store"
import axios from "core/axios"
import _ from "lodash"

const emptyObject = {}


export const PERMISSIONS = {
  ADMIN: "Admin",
  HARVEST: "Harvest",
  CALENDAR: "Calendar",
  EXPENSIFY: "Expensify"
}

export const selectUser = () => actions.get("user", emptyObject)
export const getToken = () => firebase.auth().currentUser?.getIdToken()


export const selectAccounts = () => actions.get("accounts", emptyObject)
export const selectAccount = () => {
  const accountId = actions.get("accountId")
  return actions.get(`accounts.${accountId}`, emptyObject)
}
export const setAccountId = (accountId) => actions.set("accountId", accountId)

export const selectPermissions = () => {
  const { permissions } = selectAccount()
  const { email } = selectUser()

  return _.mapValues(PERMISSIONS, (permission) => _.get(permissions, [email, permission]))
}


export const checkPipelineStatus = (account) => {
  const { deals_api_key, deals_app_key } = account
  return Boolean(
    deals_api_key &&
    deals_app_key
  )
}

export const checkSheetStatus = (account) => {
  const { sheet_api_key } = account
  const hasPipeline = checkPipelineStatus(account)
  return Boolean(
    hasPipeline &&
    sheet_api_key
  )
}

export const checkHarvestStatus = (account) => {
  const { harvest_api_key, harvest_account_id } = account
  const hasSheet = checkSheetStatus(account)
  return Boolean(
    hasSheet &&
    harvest_api_key &&
    harvest_account_id
  )
}

export const checkExpensifyStatus = (account) => {
  const { expensify_user_id, expensify_user_secret } = account
  const hasSheet = checkSheetStatus(account)
  return Boolean(
    hasSheet &&
    expensify_user_id &&
    expensify_user_secret
  )
}

export const fetchAccounts = async () => {
  try {
    setLoader("fetchAccounts")
    const accounts = await axios.get("/accounts")
      .then(({ data }) => data.reduce((acc, account) => {
        acc[account.id] = account
        return acc
      }, {}))

    actions.set("accounts", accounts)
  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("fetchAccounts")
  }
}


export const createAccount = async ({ name }, callback = _.noop) => {
  try {
    setLoader("createAccount")
    const account = await axios.post("/accounts", { name })
      .then(({ data }) => data)

    actions.set(`accounts.${account.id}`, account)
    setAccountId(account.id)
    await callback()
  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("createAccount")
  }
}


export const updateAccount = async ({ accountId, ...values }, callback = _.noop) => {
  try {
    setLoader("updateAccount")
    const account = await axios.post(`/accounts/${accountId}`, values)
      .then(({ data }) => data)

    actions.set(`accounts.${account.id}`, account)
    await callback()
  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("updateAccount")
  }
}


export const fetchUser = async () => {
  try {
    setLoader("fetchUser")
    const user = await axios.get("/user")
      .then(({ data }) => data)
    actions.set("user", user)

  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("fetchUser")
  }
}

export const initAuth = () => new Promise(async (resolve) => { // eslint-disable-line
  try {
    setLoader("initAuth")
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .catch(error => setAlertError(error.message))
    await firebase.auth().getRedirectResult()
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        actions.unset()
      } else {
        await fetchUser()
        await fetchAccounts()
      }
      resolve(null)
    })
  } catch (error) {
    setAlertError(error.message)
    resolve(null)
  } finally {
    clearLoader("initAuth")
  }
})


export const login = async () => {
  try {
    setLoader("login")
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope("https://www.googleapis.com/auth/calendar")
    await firebase
      .auth()
      .signInWithRedirect(provider)
  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("login")
  }
}


export const logout = () => firebase.auth().signOut()


export const selectPaymentMethod = (id) => actions.get(`paymentMethods.${id}`, emptyObject)
export const setPaymentMethod = (paymentMethod) => actions.set(`paymentMethods.${paymentMethod.id}`, paymentMethod)
export const fetchPaymentMethod = async (id) => {
  try {
    setLoader("fetchPaymentMethod")
    const paymentMethod = await axios.get(`/stripe/paymentMethods/${id}`)
      .then(({ data }) => data)
    setPaymentMethod(paymentMethod)

  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("fetchPaymentMethod")
  }
}


export const selectPrices = () => actions.get("prices", emptyObject)
export const fetchPrices = async () => {
  try {
    setLoader("fetchPrices")
    const prices = await axios.get("/stripe/prices")
      .then(({ data }) => data)
    actions.set("prices", prices)

  } catch (error) {
    setAlertError(error.message)
  } finally {
    clearLoader("fetchPrices")
  }
}