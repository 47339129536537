import axios, { NO_AUTH_HEADER } from "core/axios"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"


export const initFirebase = async () => {
  const config = await axios.get("/firebase-config", {
    headers: {
      authorization: NO_AUTH_HEADER
    }
  })
    .then(({ data }) => data)

  firebase.initializeApp(config)
}

export default firebase